<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.73112 20.8327C7.22404 21.0974 6.6106 21.0508 6.14933 20.7125C5.68807 20.3743 5.45921 19.8032 5.55921 19.24L6.36855 14.6011L2.96113 11.3357C2.54463 10.9385 2.39199 10.3381 2.56823 9.79024C2.74446 9.24235 3.21852 8.84353 3.78847 8.76365L8.51945 8.08737L10.6553 3.82659C10.9086 3.3156 11.4296 2.99232 11.9999 2.99232C12.5702 2.99232 13.0912 3.3156 13.3445 3.82659L15.4803 8.08737L20.2113 8.76365C20.7813 8.84353 21.2553 9.24235 21.4316 9.79024C21.6078 10.3381 21.4552 10.9385 21.0387 11.3357L17.6312 14.6011L18.4406 19.241C18.5406 19.8042 18.3117 20.3753 17.8505 20.7135C17.3892 21.0518 16.7757 21.0984 16.2687 20.8337L11.9999 18.6278L7.73112 20.8327Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
svg {
  fill: white;
}
</style>